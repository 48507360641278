import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import { ThemeProvider } from '@mui/material/styles'
import theme from 'theme'
import Home from "pages/Home"
import Links from "pages/Links"
import Prints from "pages/Prints"

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <Router>
          <Routes>
            <Route index element={<Home/>}/>
            <Route path="links" element={<Links/>}/>
            <Route path="prints" element={<Prints/>}/>
          </Routes>
        </Router>
      </ThemeProvider>
    </>
  )
}

export default App
