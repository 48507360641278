import { useState } from "react"
import {
  Box,
  Grid,
  ButtonBase,
  Typography
} from "@mui/material"

interface Props {
  media_title?: string,
  media_date?: string,
  media?: React.ReactNode,
  content_title?: string
  content?: React.ReactNode,
  images: any[],
  links: any[],
}

const Post = ({ media_title, media_date, media, content_title, content, links, images }: Props) => {
  
  const [viewRelated, setViewRelated] = useState(false)

  return (
    <Box paddingBottom={10}>
      <Grid container wrap="wrap">
        <Grid item xs={12} sm={12} md={4}>
          {media}
          <Grid container wrap="nowrap">
            <Grid item xs={12}>
              <Typography paddingLeft={0.25} fontFamily="Inconsolata" fontSize={16} fontWeight={400}>
                {media_title}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography textAlign="right" paddingRight={0.25} fontFamily="Inconsolata" fontSize={16} fontWeight={3400}>
                {media_date}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={8} paddingLeft={{xs: 0, sm: 0, md: 3}} paddingTop={{xs: 3, sm: 3, md: 0}}>
          <Typography paddingBottom={1} fontFamily="Ranade" fontSize={24} fontWeight={700}>{content_title}</Typography>
          {content}
          <Box margin={1}></Box>
          {links.map((x) => 
            <Box paddingRight={1} display="inline">
              <ButtonBase onClick={() => window.open(`${x.url}`, "_blank")}>
                <Typography fontFamily="Inconsolata" fontSize={13}>
                  [{x.label}]
                </Typography>   
              </ButtonBase>
            </Box>
          )}
          { images.length > 0 && ( // Related images
            <>
              <Box margin={1}></Box>
              <ButtonBase onClick={() => setViewRelated(!viewRelated)}>
                <Typography fontFamily="Inconsolata" fontSize={12} fontStyle={"italic"}>
                  See Related
                </Typography>   
              </ButtonBase>
              <Box margin={1}></Box>
              { viewRelated && (
                <Grid container wrap="wrap" spacing={1}>
                {images.map((x) => 
                  <Grid item xs={6} sm={4} md={3}>
                    
                    { (x.img.endsWith('.png') || x.img.endsWith('.jpg')) && (
                      <img src={x.img} alt="" width="100%"></img> 
                    )}

                    { x.img.endsWith('.mp4') && (
                      <video width="100%" autoPlay loop>
                        <source src={x.img} type="video/mp4"></source>
                      </video>
                    )}

                  </Grid>
                )}
              </Grid>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

export default Post