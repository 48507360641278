import { Grid, Box, Typography } from "@mui/material"
import Page from "components/Page"

const Links = () => {
  return (
    <Page>
      <Grid container direction="column" alignItems="center" justifyContent="center" padding={20}>    
        <Typography fontFamily="General Sans" fontSize={16}>
            Please send any enquiries about printing or physical works to
            <span>&nbsp;</span>
            <a href="mailto:prints@pxlq.art" target="_blank" rel="noreferrer">prints@pxlq.art</a>
        </Typography>
        <Box margin={5}></Box>
      </Grid>
    </Page>
  )
}

export default Links
