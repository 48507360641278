import { Container, Grid, Box } from '@mui/material'
import Footer from 'components/Footer'

const Page = ({ children }) => {
  return (
    <>
      <Grid container wrap='wrap'>
        <Grid item xs={12} sm={12} md={12}>
          <Box margin={10}></Box>
          <Container>
            {children}
          </Container>
          <Footer/>
        </Grid>
      </Grid>
    </>
  )
}

export default Page