import { Typography } from "@mui/material"

interface Props {
  label?: string,
  url?: string
}

const Link = ({ label, url }: Props) => {
  return (
    <Typography fontFamily="Inconsolata" fontSize={16} padding={0.1}>
      <a href={url} target="_blank" rel="noreferrer">
        {label}
      </a>
    </Typography>
  )
}

export default Link