import {Box } from "@mui/material"
import Page from "components/Page"
import Content from "components/Content"

const Home = () => {
  return (
    <Page>
      <Box padding={{xs: 2, sm: 2, md: 2}}>
        <Content/>
      </Box>
    </Page>
  )
}

export default Home
