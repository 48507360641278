import { useEffect, useState, useRef } from "react"
import useWindowSize from "hooks/useWindowSize"

interface Props {
  url: string
  hash?: string
}

const Sketch = ({ url, hash="0x" }: Props) => {
  const [sketch, setSketch] = useState('')
  const [dependencies, setDependencies] = useState([])
  const [params, setParams] = useState({})
  const [width, setWidth] = useState(0)
  const elementRef = useRef(document.createElement("div"))
  const windowSize = useWindowSize()

  useEffect(() => {
    fetch(url, {method: 'GET'})
    .then(response => response.json())
    .then(data => {
      setSketch(data.sketch)
      setDependencies(data.dependencies)
      setParams(data.params)      
    })
    .catch(e => console.log(e))
  }, [url])

  let source = `
<!DOCTYPE HTML>
<html lang='en'>
  <head>
    <script>let hash='${hash}'</script>
    <meta charset='utf-8'>
    <style type='text/css'>
      html {
        cursor: crosshair;
      }
      body {
        margin: 0;
        padding: 0;
      }
      canvas {
        padding: 0;
        margin: auto;
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
    </style>
  </head>
  ${dependencies.map(x => `<script src=${x}></script>`).join('\n')}
  <script>let params=${JSON.stringify(params)}</script>
  <script>${sketch}</script>
  <body></body>
</html>`

  useEffect(() => {
    setWidth(elementRef.current.offsetWidth)
  }, [windowSize.width])

  let size = Math.floor(width)+"px"

  return (
    <div ref={elementRef}>
      <iframe title={url} width={size} height={size} frameBorder="0" scrolling="no" srcDoc={source}/>
    </div>
  )
}

export default Sketch