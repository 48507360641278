import { Box, Link, Typography } from "@mui/material"

let navigation = [
  {label: "HOME", url: "/"},
  {label: "LINKS", url: "/links"},
  {label: "PRINTS", url: "/prints"}
]

const Footer = () => {
  return (
    <Box display="inline-block" width="100%" color="#E0D3D3" fontFamily="Inconsolata" fontSize={15} justifyContent="center" sx={{backgroundColor: "#000010"}}>
      <Box margin={5}></Box>
      <Box display="flex" justifyContent="center">
        {navigation.map((item, i) => (
          <Link 
            key={i} 
            href={item.url} 
            paddingLeft={1} 
            paddingRight={1}
            sx={{
              "color": "#E0D3D3",
              "&:hover": {
              color: "black",
              backgroundColor: "#E0E0E0"
              }
            }}
            >
            <Typography 
              color="inherit"
              fontFamily="inherit" 
              fontSize="inherit"
              >
              {item.label}
            </Typography>
          </Link>
        ))}
      </Box>
      <Box margin={5}></Box>
      <Box display="flex" justifyContent="center"> 
        <Typography fontFamily="inherit" fontSize="inherit">
          DISCLAIMER
        </Typography>
      </Box>
      <Box margin={1}></Box>
      <Box display="flex" justifyContent="center" paddingLeft={{xs: 5, sm: 10, md: 15}} paddingRight={{xs: 5, sm: 10, md: 15}}> 
        <Typography fontFamily="inherit" fontSize="inherit" maxWidth={600}>
          I am a creative experimenting with new technology. Anything I create may break or disappear, 
          at any point, without notice. If you choose to interact with my work, do so completely at your own risk. 
          I offer no guarantees, no promises, and no roadmaps. I am grateful for any support, but you are soley
          responsible for your interactions with my work. Do not speculate on my artwork or other creations. Assume the 
          future value of anything I create will be zero.
        </Typography>
      </Box>
      <Box margin={5}></Box>
      <Box display="flex" justifyContent="center"> 
        <Typography fontFamily="inherit" fontSize="inherit">
          &copy;2024 by PXLQ LLC
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center"> 
        <Typography fontFamily="inherit" fontSize="inherit">
          All Rights Reserved
        </Typography>
      </Box>
      <Box margin={10}></Box>
    </Box>
  )
}

export default Footer