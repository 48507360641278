import { Box, Typography } from "@mui/material"
import Post from "components/Post"
import Frame from "components/Frame"
import Sketch from "components/Sketch"

const Content = () => {
  return (
    <>
    <Post
      media_title = "Iris"
      media_date = "2024"
      media = {<Sketch url={"https://api.pxlq.art/project/botany"}/>}
      content_title = "Algorithmic botany"
      content = {
        <>
          <Typography fontFamily="General Sans" fontSize={16}>
           L-systems are a grammar system introduced by biologist Aristid Lindenmayer in the 1960s used to describe the organizational
           structure of plants, taking advantage of the self-similarity that plants exhibit to model growth and branching patterns. 
           This formal language can be incorporated into algorithms to generate 2D/3D structures.
          </Typography>
          <Box margin={2}></Box>
          <Typography fontFamily="General Sans" fontSize={16}>
           L-systems are not only used to model branches, but also leaves, flowers, and berries etc. via stamping. Here stamps are generative
           3D meshes integrated into the algorithm. Meshes were created with floral imagery (Unsplash) and InstantMesh, a single-image-to-model LRM (Large Reconstruction Model).
          </Typography>
        </>
      }
      links = {[
        {label: "abop", url: "http://algorithmicbotany.org/papers/abop/abop.pdf"},
        {label: "instant.mesh", url: "https://github.com/TencentARC/InstantMesh"},
        {label: "hugging.face", url: "https://huggingface.co/spaces/TencentARC/InstantMesh"}
      ]}
      images = {[
      ]}
    />
    <Post
      media_title = "Amorphous Packing"
      media_date = "2024"
      media = {<img src="/media/lg/POLY.png" alt="" width="100%"></img>}
      content_title = "Genuary"
      content = {
        <>
          <Typography fontFamily="General Sans" fontSize={16}>
            One program per day for 31 days in January. Well not quite... I did my best! I was making steady progress until halfway I fell down
            the rabbit hole of poly packing.
          </Typography>
          <Box margin={2}></Box>
          <Typography fontFamily="General Sans" fontSize={16}>
            Prompts are a great way to break out of your comfort zone, learn techniques, and find inspiration for future projects. Thank you to
            @piterpasma and other contributors for the prompts and a lot of fun to bring in the new year.
          </Typography>
        </>
      }
      links = {[
        {label: "prompts", url: "https://genuary.art/prompts"}
      ]}
      images = {[
        {img: "/media/sm/cfrda.png"},
        {img: "/media/sm/g6s05.png"},
        {img: "/media/sm/jggfn.png"},
        {img: "/media/sm/e6i7l.png"},
        {img: "/media/sm/g14qe.mp4"},
      ]}
    />
    <Post
      media_title = "Noto Mathcastles Remix"
      media_date = "2023"
      media = {<img src="/media/lg/NOTO.png" alt="" width="100%"></img>}
      content_title = "Art programming interfaces"
      content = {
        <>
          <Typography fontFamily="General Sans" fontSize={16}>
            Inspired by the "art as an api" idea demonstrated by Terraforms by Mathcastles. The idea being, art itself can serve as a input
            into another piece of work. With Terraforms, there is the Hypercastle - a Hyperstructure made up of thousands of individual components
            that can be directly queried for information.
          </Typography>
          <Box margin={2}></Box>
          <Typography fontFamily="Inconsolata" fontSize={16}>
            terraform art --generate-random
          </Typography>
        </>
      }
      links = {[
        {label: "hypercastle", url: "https://enterdream.xyz/3d/index.html?id=2872"},
        {label: "mathcastles", url: "https://twitter.com/mathcastles"}
      ]}
      images = {[
        {img: "/media/sm/yxt24.png"},
        {img: "/media/sm/g06wr.mp4"},
        {img: "/media/sm/g3bcj.png"},
      ]}
    />

    <Post
      media_title = "Faces"
      media_date = "2021"
      media = {<img src="/media/lg/FACES.png" alt="" width="100%"></img>}
      content_title = "Generative faces"
      content = {
        <>
          <Typography fontFamily="General Sans" fontSize={16}>
            A truly generative attempt at a picture for proof / profile pic (PFP) series. Here was a curated set of fifty unique 
            outputs from thousands. Algorithmic faces are hard, at least in the absence of hard-coded features and obsessive curation.
          </Typography>
          <Box margin={2}></Box>
          <Typography fontFamily="General Sans" fontSize={16}>
            This algorithm starts with a basic rectangle partitioning scheme to get the head. It has an edge detection component to
            remove corner partitions and round adjacent tiles, giving a face-like appearance. It uses various colors, grid schemes, 
            and pattern tiling to give a robot-esque vibe.
          </Typography>
          <Box margin={2}></Box>
          <Typography fontFamily="General Sans" fontSize={16}>
            Note: The image shown is not part of the original set. It was a 1/1 curated output using an improved version of the 
            algorithm (See v2), auctioned on Foundation.
          </Typography>
        </>
      }
      links = {[
        {label: "faces.v1", url: "https://opensea.io/collection/generative-faces"},
        {label: "faces.v2", url: "https://opensea.io/collection/generativefaces"},
        {label: "foundation", url: "https://foundation.app/@pxlq/foundation/67711"}
      ]}
      images = {[
        {img: "/media/sm/ovbxw.png"},
        {img: "/media/sm/8k5f0.png"},
        {img: "/media/sm/8zday.png"},
      ]}
    />

    <Post
      media_title = "Gravity: Glitch"
      media_date = "2021"
      media = {<img src="/media/lg/GRAVITY.png" alt="" width="100%"></img>}
      content_title = "Gravity series"
      content = {
        <>
          <Typography fontFamily="General Sans" fontSize={16}>
            There are five variants of my take on flow fields. Physics is often a useful framework to conceptualize 
            what you want shapes to do or what features you're interested in. In this case, simple lines are undergoing 
            various gravitational pulls. Every component of this work is a straight line. Within each field, there may be zero,
            one, two, or multiple gravity wells, of which each line's orientation is slightly altered by, depending on their distance, 
            causing spiral patterns, implosions, or deep otrusions.
          </Typography>
          <Box margin={2}></Box>
          <Typography fontFamily="General Sans" fontSize={16}>
            Glitch arose from a bug that caused a large skipping segment in the coloring portion of the algorithm. 
            I just loved how it looked so much, sort of looks like a space zebra, I kept it in. Perhaps the gravity was too strong, 
            leaving a small tear in the fabric of space.
          </Typography>
        </>
      }
      links = {[
        {label: "foundation", url: "https://foundation.app/@pxlq/foundation/75129"},
      ]}
      images = {[
        {img: "/media/sm/vz2qe.png"},
        {img: "/media/sm/3k8bo.png"},
        {img: "/media/sm/mduip.png"},
        {img: "/media/sm/mloym.png"},
        {img: "/media/sm/ur4tc.png"},
        {img: "/media/sm/weo2p.png"},
        {img: "/media/sm/xos1s.png"},
      ]}
    />

    <Post
      media_title = "0x Ships"
      media_date = "2021"
      media = {<img src="/media/lg/SHIP.png" alt="" width="100%"></img>}
      content_title = "Community-driven generative algorithms"
      content = {
        <>
          <Typography fontFamily="General Sans" fontSize={16}>
            Nine hundred and ninety nine [999] artifacts. Hand crafted by the community, with a bit of algorithmic assistance.
            Those who interacted fed a block hash and select parameters to a generative algorithm using forced-symmetry to randomly 
            generate body, wings, thrusters and shooters.
          </Typography>
          <Box margin={2}></Box>
          <Typography fontFamily="Inconsolata" fontSize={14}>
            ---------------------█═»<br/>
            ---------------------█═»<br/>
            ---------------------██═─ - -<br/>
            ---------------------░░<br/>
            -------------------▓░█▓█░░»<br/> 
            ---------------------░░ <br/>
            ---------------------██═─- - <br/>
            ---------------------█═»<br/>
            ---------------------█═»<br/>
          </Typography>
          <Box margin={2}></Box>
          <Typography fontFamily="General Sans" fontSize={16}>
            The idea was to create a rich algorithm capable of generating a wide range of ship-esque outputs from small subunits. 
            This was conceptualized from playing around with ASCII blocks that sort of looked like various parts of a spaceship and 
            a short lived phase of storing ASCII art on Polygon [L2]. Can you find your ship floating around in the gallery?
          </Typography>
        </>
      }
      links = {[
        {label: "space.gallery", url: "https://ships.pxlq.art"},
        {label: "ethblock.art", url: "https://ethblock.art/style/35"},
      ]}
      images = {[
        {img: "/media/sm/9fmpl.png"},
        {img: "/media/sm/uhd47.png"},
        {img: "/media/sm/jp6vp.png"},
        {img: "/media/sm/mz7nb.png"},
      ]}
    />

    <Post
      media_title = "Infinethum No. 4"
      media_date = "2021"
      media = {<Frame url={"https://api.infinethum.xyz/variant/4/2"}/>}
      content_title = "Infinethum"
      content = {
        <>
          <Typography fontFamily="General Sans" fontSize={16}>
            A space for limited editioned generative art series. The first Powered by Art Blocks 
            (PBAB) project - which uses Art Blocks contracts under the hood to power on-chain generative art.
          </Typography>
          <Box margin={2}></Box>
          <Typography fontFamily="General Sans" fontSize={16}>
            The first project is called Coalescent, with complex patterns emerging from simple shapes and rules. 
            Thirty six initial shapes defined within a coordinate grid system coalesce with each other, giving 
            rise to unexpected patterns. Generative art variants showcase different aspects of the art, including 
            the static print, base pattern, and dynamic visualization.
          </Typography>
        </>
      }
      links = {[
        {label: "infinethum.xyz", url: "https://infinethum.xyz"},
        {label: "infinethum.ephemeral", url: "https://infinethum-ephemeral.glitch.me"},
        {label: "infinethum+hydra", url: "https://hydra.ojack.xyz/?code=czAuaW5pdEltYWdlKCdodHRwcyUzQSUyRiUyRmFwaS5pbmZpbmV0aHVtLnh5eiUyRmltYWdlJTJGNCcpJTBBc3JjKHMwKSUwQS5tb2R1bGF0ZShvc2MoMTApKSUwQS5tb2R1bGF0ZVJvdGF0ZShub2lzZSgoKSUyMCUzRCUzRSUyMCgobW91c2UueSUyMCUyRiUyMGhlaWdodCkpJTIwKiUyMDEwJTJDJTIwMCklMkMlMjA1KSUwQS5tb2R1bGF0ZVJvdGF0ZShub2lzZSgoKSUyMCUzRCUzRSUyMCgobW91c2UueCUyMCUyRiUyMHdpZHRoKSklMjAlMjAqJTIwMTAlMkMlMjAwKSUyQyUyMDUpJTBBLm91dCgpJTBB"}
      ]}
      images = {[
        {img: "/media/sm/qj4v4.png"},
        {img: "/media/sm/fsiex.png"},
        {img: "/media/sm/swpt4.png"},
        {img: "/media/sm/ua9wr.png"},
        {img: "/media/sm/cyc42.png"},
        {img: "/media/sm/jgcs4.png"},
        {img: "/media/sm/n3xe5.png"},
        {img: "/media/sm/7tdv6.png"},
        {img: "/media/sm/osf3x.png"},
        {img: "/media/sm/wzc4z.png"},
        {img: "/media/sm/hfg25.png"},
        {img: "/media/sm/5idri.png"},
      ]}
    />

    <Post
      media_title = "Cipher No. 3"
      media_date = "2020"
      media = {<img src="/media/lg/CIPHER.png" alt="" width="100%"></img>}
      content_title = "/De/cipher"
      content = {
        <>
          <Typography fontFamily="General Sans" fontSize={16}>
          An emerging scene in the crypto art space is generative art platforms and marketplaces. One of the most interesting 
          is Block Art, a platform that mints using styles from artists and creative coders. This is the first instance where 
          creative coders can actually sell an algorithm as an ERC721. Now what's really cool about it is the block hash determines
           most of the output, but one does have some control over the coloring and parameters. Hisorical blocks (like Block No. 1) 
           drive a premium. Each time a block is used as input for generating a piece, the cost of subsequent mints are increased, 
           therefore rewarding early users for minting on high-demand blocks.
          </Typography>
          <Box margin={2}></Box>
          <Typography fontFamily="General Sans" fontSize={16}>
          A project called Decipher was released on Block Art, allowing individuals to customize their art based on a style called Cipher
           - an exploration of recursive partitioning algorithm - originally released on VIV3.
          </Typography>
        </>
      }
      links = {[
        {label: "ethblock.art", url: "https://ethblock.art/style/3"},
      ]}
      images = {[
        {img: "/media/sm/y07y9.png"},
      ]}
    />

    <Post
      media_title = "Wandering"
      media_date = "2021"
      media = {<img src="/media/lg/WANDERING.png" alt="" width="100%"></img>}
      content_title = "CMYK"
      content = {
        <>
          <Typography fontFamily="General Sans" fontSize={16}>
          Going on a CMYK journey with KnownOrigin. It is no secret that I often use bright and vibrant colors in much of my work.
          KO has a sort of vibe that bodes well with my art and it is displayed well there. I put out a few single editions of 
          curated generative art experiments there.
          </Typography>
        </>
      }
      links = {[
        {label: "known.origin", url: "https://knownorigin.io/pxlq"}
      ]}
      images = {[
        {img: "/media/sm/20l29.png"},
        {img: "/media/sm/zzfbs.png"},
      ]}
    />

    <Post
      media_title = "Cyber Cities"
      media_date = "2021"
      media = {<Frame url={"https://generator.artblocks.io/14000080"}/>}
      content_title = "Dynamic digital art"
      content = {
        <>
          <Typography fontFamily="General Sans" fontSize={16}>
          Futuristic space vibes. An abstract take on futuristic cities in space. 
          One of the first Art Blocks Playground series.
          </Typography>
          <Box margin={2}></Box>
          <Typography fontFamily="General Sans" fontSize={16}>
          Art Blocks offers an incredible opportunity for art to live dynamically 
          in their native state. When the raw code (stored on-chain) is rendered live, the moving bits - which 
          I refer to as ships - infinitely wander throughout the piece.
          </Typography>
          <Box margin={2}></Box>
          <Typography fontFamily="General Sans" fontSize={16}>
          There is a similar piece of work on Async Art, where controllers
          can adjust layer states to change the cyber structures within the
          larger composition.
          </Typography>
        </>
      }
      links = {[
        {label: "gallery", url: "https://cyber.pxlq.art"},
        {label: "art.blocks", url: "https://www.artblocks.io/project/14"},
        {label: "on.cyber", url: "https://oncyber.io/art-blocks?id=14000014"},
      ]}
      images = {[
        {img: "/media/sm/bdll2.png"},
        {img: "/media/sm/rt1pz.png"},
        {img: "/media/sm/o6nqd.png"},
        {img: "/media/sm/85ef5.png"},
        {img: "/media/sm/502ib.png"},
        {img: "/media/sm/mam3b.png"},
        {img: "/media/sm/f311l.png"},
        {img: "/media/sm/jsdpb.png"},
        {img: "/media/sm/5np7q.png"},
      ]}
    />
    </>
  )
}

export default Content